<template>
  <div>
    <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">
      <div style="margin-top: 70px; margin-bottom: 100px">
        <!-- navbar -->
        <navbar-top-component></navbar-top-component>

        <!-- body -->
        <div class="container">
          <div v-if="successConfirmation == false" class="border mx-2" style="border-radius: 5px">
            <div class="row text-center mt-4">
              <div class="col">
                <div>
                  No Pembayaran
                </div>
                <div class="font-weight-bold">
                  {{transaction.code}}
                </div>
              </div>
            </div>

            <div class="row mt-4 text-center">
              <div class="col">
                <div>
                  Total Pembayaran
                </div>
                <div class="font-weight-bold">
                  Rp. {{Number(transaction.amount).toLocaleString('id')}}
                </div>
              </div>
            </div>

            <div class="row text-center mt-4">
              <div class="col">
                Silahkan Transfer ke
              </div>
            </div>

            <div class="row mt-4">
              <div class="col text-center">
                <div class="border mx-2 p-2" style="border-radius: 5px">
                  <div>
                    <img class="img-fluid" style="max-width: 100px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Bank_Syariah_Indonesia.svg/2560px-Bank_Syariah_Indonesia.svg.png" alt="">
                  </div>
                  <div class="mt-3 font-weight-bold">
                    1234567891011
                  </div>
                  <div class="mt-3">
                    a.n. Daarut Taqwa Ihsaniyya
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col text-center">
                <button class="btn btn-sm text-white" style="background-color: #0c77b4"> <b-icon-paperclip></b-icon-paperclip> Salin nomor rekening </button>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col text-center">
                <div>
                  Sudah transfer ?
                </div>
                <div>
                  Mohon upload bukti transfer
                </div>
              </div>
            </div>

            <div class="row mt-4 mb-4">
              <div class="col">
                <div class="p2">
                  <input type="file" class="form-control" @change="onFileUpload">
                </div>
              </div>
            </div>

            <div class="fixed-bottom bg-white border-top">
              <div class="row py-2">
                <div class="col mx-2">
                  <button @click="confirmationDonation()" class="btn text-white btn-block" style="background-color: #0c77b4">Selesai</button>
                </div>
              </div>
            </div>

          </div>

          <div v-else>
            <div class="row" style="margin-top: 140px;">
              <div class="col text-center">
                <b-icon-check-circle-fill variant="success" scale="5"></b-icon-check-circle-fill>
              </div>
            </div>

            <div class="row" style="margin-top: 60px;">
              <div class="col">
                <div class="text-center font-weight-bold">
                  Sukses Melakukan <br> Konfirmasi Donasi
                </div>
              </div>
            </div>

            <div class="fixed-bottom bg-white border-top">
              <div class="row py-2">
                <div class="col mx-2">
                  <button @click="backToPrevious()" class="btn text-white btn-block" style="background-color: #0c77b4">Selesai</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import NavbarTopComponent from '../../../components/NavbarTopComponent.vue'
export default {
  components: { NavbarTopComponent },
  name: "CampaignDetailConfirmViews",

  data() {
    return {
      id: this.$route.params.id,

      transaction: {},
      file: null,

      successConfirmation: false,
    }
  },

  beforeMount() {
    this.getConfirmationData();
  },

  methods: {
    async getConfirmationData() {
      await axios.get(process.env.VUE_APP_API + 'donation/' + this.id, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((resp) => {
        if(resp.status == 200) {
          console.info(resp.data);
          this.transaction = resp.data.data;
        }
      })
    },

    backToPrevious() {
      this.$router.replace('/');
    },

    onFileUpload (event) {
      this.file = event.target.files[0]
    },

    async confirmationDonation() {
      console.info('-- confirmation donation --');
      const payloadData = new FormData();
      payloadData.append('file', this.file, 'receipt');
      const url = process.env.VUE_APP_API + 'donation/' + this.id + '/confirmation';
      await axios.post(url, payloadData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }
      }).then((res) => {
        this.successConfirmation = true;
        console.info(res);
      })
    }
  }
}
</script>

<style>

</style>